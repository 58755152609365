<template>
    <div>
        <el-form :model="form_data" :rules="form_rules" :ref="form_name" size="mini" label-position="right" label-width="100px">
            <template v-if="form_data.id&&form_data.id>0">
                <el-form-item label="ID">{{form_data.id}}</el-form-item>
                <el-form-item label="组别-名" prop="group_name">{{form_data.group_name}}</el-form-item>
                <el-form-item label="组别-键" prop="group_code">{{form_data.group_code}}</el-form-item>
            </template>
            <el-form-item label="名称" prop="item_name"><el-input v-model="form_data.item_name"></el-input></el-form-item>
            <el-form-item label="键名" prop="item_code"><el-input v-model="form_data.item_code"></el-input></el-form-item>
            <el-form-item label="键值" prop="item_value"><el-input v-model="form_data.item_value"></el-input></el-form-item>
            <el-form-item label="输入类型" prop="input_type"></el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {getDetail, submit} from "@/api/fusion/setting-group-item";
    export default {
        name: "setting-group-item-detail",
        props:{
            action:{
                value:String,
                default:'add'
            },
            rowId:{
                value:String,
            },
            groupId:{
                value:String,
            }
        },
        data(){
            return{
                form_name:'setting_group_item_detail',
                form_data:{},
                form_rules:{},
            }
        },
        computed:{
            formDisabled(){
                return this.action === 'view' || !this.action;
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                if(this.rowId) {
                    getDetail(this.rowId).then(resp => {
                        this.form_data = resp.data
                    })
                }else{
                    this.form_data.group_id = this.groupId
                }
            },
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        submit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
