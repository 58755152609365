<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                    <el-row>
                        <el-col>
                            <el-form-item label="名称" prop="group_name">
                                <el-input v-model="search_option.group_name" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="键名" prop="group_key">
                                <el-input v-model="search_option.group_key" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="键值" prop="group_key">
                                <el-input v-model="search_option.group_key" clearable></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" icon="el-icon-document-add" size="mini" @click="onCreateTap">新增</el-button>
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div  style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border style="width:100%;">
                    <el-table-column prop="group_name" label="组别"></el-table-column>
                    <el-table-column prop="group_code" label="组别-键名"></el-table-column>
                    <el-table-column prop="item_name" label="名称"></el-table-column>
                    <el-table-column prop="item_code" label="键名"></el-table-column>
                    <el-table-column prop="item_value" label="键值"></el-table-column>
                    <el-table-column prop="input_type_name" label="类型"></el-table-column>
                    <el-table-column prop="" label="操作">
                        <template #default="scope">
                            <el-button size="mini" type="primary" @click="onEditTap(scope.row)">编辑</el-button>
                            <el-button size="mini" type="info" @click="onViewTap(scope.row)">查看</el-button>
                            <el-button size="mini" type="danger" @click="onRemoveTap(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog v-model="dialogFormVisible" :title="dialogFormTitle" @close="onTellDialogClose" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <setting-group-item-detail :action="dialogFormAction" :row-id="dialogFormRowId" :group-id="$route.query.group_id" @onTellDialogClose="onTellDialogClose"></setting-group-item-detail>
        </el-dialog>
    </div>
</template>

<script>
    import {getList,remove} from "@/api/fusion/setting-group-item";
    import SettingGroupItemDetail from './setting-group-item-detail'
    export default {
        name: "setting-group-item-list",
        components:{SettingGroupItemDetail},
        data(){
            return{
                dialogFormTitle:'',
                dialogFormVisible:false,
                dialogFormRowId:0,
                dialogFormAction:'',//create view edit

                loading:false,
                search_form_name:'setting_group_item_search',
                page_option:{page:1,size:10,total:0},
                search_option:{},
                base_search_option:{group_id:''},
                page_list:[],
            }
        },
        created(){
            this.base_search_option.group_id = this.$route.query.group_id
            this.initPage()
        },
        methods:{
            initPage() {
                this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                let sopt = Object.assign({},searchOpt,this.base_search_option)

                getList(pageOpt.page, pageOpt.size, sopt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            onViewTap(row) {
                console.log('onViewTap:', row)
                this.dialogFormRowId = row.id
                this.dialogFormAction = 'view'
                this.dialogFormVisible = true
            },
            onEditTap(row) {
                console.log('onEditTap:', row)
                this.dialogFormAction = 'edit'
                this.dialogFormRowId = row.id
                this.dialogFormVisible = true
            },
            onCreateTap() {
                console.log('onCreateTap')
                this.dialogFormRowId = 0;
                this.dialogFormAction = 'add'
                this.dialogFormVisible = true
            },
            onRemoveTap(row) {
                this.$messageBox.confirm('确定删除当前数据?', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确定'
                }).then(() => {
                    remove(row.id).finally(() => {
                        this.reloadPage()
                    })
                })
            },
            onTellDialogClose() {
                this.dialogFormVisible = false;
                this.dialogFormRowId = 0;
                this.reloadPage()
            },
        }
    }
</script>

<style scoped>

</style>
